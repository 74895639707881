import React, { useState, useMemo, useEffect, useContext } from "react";
import {
  createColumnHelper,
  getSortedRowModel,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import IndeterminateCheckbox from "../layout/IndeterminateCheckbox";
import { rankItem } from "@tanstack/match-sorter-utils";
import ApplicationLogo from "./ApplicationLogo";
import { PortalContext } from "../../PortalContext";
import TableLayout, {
  TableBody,
  TableDataCell,
  TableDataRow,
  TableHeader,
  TableHeaderRow,
} from "../layout/design/Table";
import { ReactComponent as GhostImage } from "../../images/ghost.svg";
function ApplicationTable({ appData, setSelected, clear }) {
  const { t } = useTranslation("global");
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({
    type: false,
  });
  const { deviceMode } = useContext(PortalContext);
  const [rowSelection, setRowSelection] = useState({});
  const [lastSelected, setLastSelected] = useState([]);
  const data = useMemo(() => [...appData], [appData]);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("select", {
      header: ({ table }) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      className: "w-16",
      classNameHeader: "w-16",
      cell: ({ row }) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect}
          indeterminate={row.getIsSomeSelected()}
          onChange={(e) => {
            handleSelection(e, row);
          }}
        />
      ),
      enableResizing: false,
      enableSorting: false,
    }),
    columnHelper.accessor("logo", {
      header: () => "",
      cell: (info) => <ApplicationLogo logoUrl={info.getValue()} />,
      enableSorting: false,
      enableResizing: false,
    }),
    columnHelper.accessor("displayName", {
      header: () => (
        <span>{t("pages.application.table.application_name")}</span>
      ),
      cell: (info) => info.getValue(),
      className: "text-left cursor-pointer truncate px-2",
      classNameHeader: "cursor-pointer",
      size: 350,
      minSize: 150,
    }),
    columnHelper.accessor("status", {
      header: () => <span>{t("pages.application.table.status")}</span>,
      cell: (info) => (
        <span>
          {t(`pages.application.table.${info.getValue()}`, info.getValue())}
        </span>
      ),
      minSize: 150,
      size: 150,
    }),
    columnHelper.accessor("type"),
  ];
  useEffect(() => {
    setSelected(table.getSelectedRowModel().flatRows);
  }, [rowSelection]);
  useEffect(() => {
    table.getColumn("type").setFilterValue(deviceMode);
    table.setRowSelection({});
  }, [deviceMode]);
  useEffect(() => {
    table.resetRowSelection(true);
  }, [clear]);
  const handleSelection = (e, row) => {
    setLastSelected(row);
    if (e.nativeEvent.shiftKey) {
      const sortedRows = table.getSortedRowModel().rows;
      const lastSelectedIndex = sortedRows.findIndex(
        (r) => r.id == lastSelected.id
      );
      const clickedIndex = sortedRows.findIndex((r) => r.id == row.id);

      if (lastSelectedIndex < clickedIndex) {
        for (let i = lastSelectedIndex; i <= clickedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      } else {
        for (let i = clickedIndex; i <= lastSelectedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      }
    } else {
      row.toggleSelected();
    }
  };

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      globalFilter,
      rowSelection,
      sorting,
      columnVisibility,
      columnFilters,
    },
    enableColumnResizing: true,
    enableColumnFilters: true,
    columnResizeMode: "onChange",
    enableRowSelection: true,
    enableGlobalFilter: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onglobalFilterFn: fuzzyFilter,
    GlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  return (
    <>
      {table.getRowModel().rows.length > 0 ? (
        <TableLayout>
          <TableHeaderRow>
            {table
              .getHeaderGroups()
              .map((headerGroup) =>
                headerGroup.headers.map((header) => (
                  <TableHeader header={header} />
                ))
              )}
          </TableHeaderRow>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
            <TableDataRow isSelected={row.getIsSelected()}>
              {row.getVisibleCells().map((cell) => (
                <TableDataCell cell={cell} />
              ))}
            </TableDataRow>
            ))}
          </TableBody>
        </TableLayout>
      ) : (
        <NoApplicationsMessage />
      )}
    </>
  );
}

const NoApplicationsMessage = () => {
  const { t } = useTranslation("global");
  return (
    <div className=" py-8">
      <div className="text-fgray-400 text-xl p-5 space-y-4 text-center place-items-center w-[835px] ">
        <GhostImage />
        <p>{t("pages.application.table.no_applications")}</p>
      </div>
    </div>
  );
};

export default ApplicationTable;
