import React from "react";
import IconButton from "./IconButton";

function ActionButton({ Icon, children, primary, ...attributes }) {
  return (
    <IconButton Icon={Icon} {...attributes}>
      {children}
    </IconButton>
  );
}

export default ActionButton;
