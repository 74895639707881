import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import CategoryCombo from "../layout/CategoryCombo";
import { ReactComponent as AssignLogo } from "../icons/assign.svg";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../layout/ModalCard";
import ActionButton from "../layout/buttons/ActionButton";

function DeviceAssignUnenrolledModal({ selected, device, clear }) {
  const { t } = useTranslation("global");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selCategory, setSelCategory] = useState(0);
  const toggle = () => {
    (selected?.length > 0 || device) && setShow(!show);
  };
  const hide = () => setShow(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, hide);
  function handleAssignment(event) {
    event.preventDefault();
    setLoading(true);
    let reqBody = {
      kind: "asg",
      target: "dev",
      detail: [],
    };
    if (selCategory !== 0) {
      selected.map((device) => {
            reqBody.detail.push({
              deviceId: device?.original?.id || device.id,
              categoryId: selCategory,
              deviceType: "unenrolled",
            });
          });
    }
    selCategory &&
      postApi("/tasks/", reqBody).then((response) => {
        toggle();
        setLoading(false);
        clear && clear();
      });
  }
  return (
    <>
      <ActionButton
        id="assignUnenrolled"
        Icon={AssignLogo}
        onClick={toggle}
        tooltip={`tooltip.device.unenrolled.assign`}
        subtle
      >
        {t("pages.device.assign")}
      </ActionButton>
      {show && (
        <ModalCard wrapper={wrapper}>
          <ModalCardTitle>
            {t("pages.device.modal.assign.title")}
          </ModalCardTitle>
          <ModalCardBody>
            <h2 className="text-fgray-400 font-small text-justify py-2">
              {t("pages.device.modal.assign.bodyunenrolled", {
                count: selected.length,
              })}
            </h2>
            <div className="text-left">
              {t("pages.device.table.category")}
              <CategoryCombo
                className="text-red-700"
                setSelected={setSelCategory}
                includeUnassigned={true}
              />
            </div>
          </ModalCardBody>
          <ModalCardButtons>
            <StandardButton onClick={hide} subtle>
              {t("pages.device.modal.cancel")}
            </StandardButton>
            <StandardButton onClick={handleAssignment} loading={loading}>
              {t("pages.device.assign")}
            </StandardButton>
          </ModalCardButtons>
        </ModalCard>
      )}
    </>
  );
}

export default DeviceAssignUnenrolledModal;
