import React from "react";
import Tooltip from "../layout/tooltip/Tooltip";
import { useTranslation } from "react-i18next";

function DeviceStatusIndicator({ status }) {
  const {t} = useTranslation("global")
  let bgColor = "";
  let tooltipKey = null
  switch (status.status) {
    case 200:
      bgColor = " bg-lime-600";
      tooltipKey = `metrics.status.200.title`
      break;
    case 300:
      bgColor = " bg-fstatus-300";
      tooltipKey = `metrics.${status.id}.warning`
      break;
    case 400:
      bgColor = " bg-fstatus-400";
      tooltipKey = `metrics.${status.id}.danger`
      break;

    default:
      break;
  }
  return (
      <Tooltip title={t(tooltipKey,"")}>
        <p className={bgColor + ` rounded-full w-2 h-2 z-4`}></p>
      </Tooltip>
  );
}

export default DeviceStatusIndicator;
