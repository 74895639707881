import React, { useContext } from "react";
import NewGroup from "./NewGroup";
import { PortalContext } from "../../PortalContext";
import GroupCard from "./GroupCard";
function GroupGrid({ rooms, categories, filter }) {
  const { taskOnProgress } = useContext(PortalContext);

  return (
    <>
      <div className="grid grid-cols-4 gap-4 mb-12 items-start w-full">
        {categories.map((category) => {
          return filter === 0 || filter === 1 ? (
            <GroupCard groupInfo={category} key={category.id} />
          ) : (
            ""
          );
        })}
        {rooms.map((room) => {
          return filter === 0 || filter === 2 ? (
            <GroupCard groupInfo={room} key={room.id} />
          ) : (
            ""
          );
        })}
        {taskOnProgress.map((task) => {
          if (
            (task.target === "rom" || task.target === "cat") &&
            task.kind === "cre"
          ) {
            // if creating a room or category
            <NewGroup type={task.target} info={task.detail}></NewGroup>;
          }
        })}
      </div>
    </>
  );
}

export default GroupGrid;
