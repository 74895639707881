import React, { useState, useRef } from "react";
import { ReactComponent as MoreLogo } from "../../icons/ellipsis_hrz.svg";
import { useToggleComponent } from "../../../tools/hooks";
import IconButton from "./IconButton";
import Tooltip from "../tooltip/Tooltip";

function MoreButtons(props) {
  const [showMore, setShowMore] = useState(false);
  const toggle = () => setShowMore(!showMore);
  const action = (a) => {
    toggle();
    a();
  };
  const wrapperRef = useRef(null);
  useToggleComponent(wrapperRef, setShowMore);
  return (
    <>
      <IconButton
        icon
        id="more"
        Icon={MoreLogo}
        onClick={() => toggle()}
        tooltip={`tooltip.buttons.more`}
        thin
      />
      {showMore && (
        <div
          className="mt-14 p-2 absolute float border w-[180px] shadow-lg rounded-lg bg-white z-30 cursor-pointer"
          ref={wrapperRef}
        >
          {props.free
            ? props.children
            : props.children.map(
                (button, index) =>
                  button && (
                    <MoreButtonsChild
                      id={button.props.id}
                      key={index}
                      onClick={() => action(button.props.onClick)}
                      // {...button.props}
                      // tooltip={button.props.tooltip}
                      tooltip={button.props.tooltip}
                      position={button.props.position}
                    >
                      {button.props.Icon && (
                        <button.props.Icon className="h-6 w-6 mr-2" />
                      )}
                      {button.props.children}
                    </MoreButtonsChild>
                  )
              )}
        </div>
      )}
    </>
  );
}

export function MoreButtonsChild({ children, Icon, tooltip, ...attributes }) {
  return (
    <div className="w-full">
      <Tooltip title={tooltip} {...attributes}>
        <button
          className="p-2 flex m-auto w-[164px] hover:font-medium font-normal hover:bg-forange-100 hover:text-forange-400 hover:border-forange-400 rounded"
          {...attributes}
        >
          {Icon && <Icon className="h-6 w-6 mr-2" />}
          {children}
        </button>
      </Tooltip>
    </div>
  );
}

export default MoreButtons;
