import React, { useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import { ReactComponent as AssignLogo } from "../icons/assign.svg";
import CategoryCombo from "../layout/CategoryCombo";
import RoomCombo from "../layout/RoomCombo";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardTitle,
  ModalCardBody,
  ModalCardButtons,
} from "../layout/ModalCard";
import ActionButton from "../layout/buttons/ActionButton";
import { PortalContext } from "../../PortalContext";
function DeviceAssignModal({ selected, clear }) {
  const { t } = useTranslation("global");
  const { deviceMode } = useContext(PortalContext);
  const [show, setShow] = useState(false);
  const [selCategory, setSelCategory] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selRoom, setSelRoom] = useState(0);
  const toggle = () => {
    selected?.length > 0 && setShow(!show);
  };
  const hide = () => setShow(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, hide);
  function handleAssignment(event) {
    event.preventDefault();
    setLoading(true);
    let reqBody = {
      kind: "asg",
      target: "dev",
      detail: [],
    };
    if (selCategory !== 0) {
      selected.map((device) => {
        reqBody.detail.push({
          deviceId: device?.original?.id || device.id,
          categoryId: selCategory,
          objectId: device?.original?.objectId || device.objectId,
          deviceType: "enrolled",
        });
      });
    }
    if (selRoom !== 0) {
      selected.map((device) => {
        reqBody.detail.push({
          deviceId: device?.original?.id || device.id,
          roomId: selRoom,
          objectId: device?.original?.objectId || device.objectId,
          deviceType: "enrolled",
        });
      });
    }
    (selCategory || selRoom) &&
      postApi("/tasks/", reqBody).then((response) => {
        toggle();
        setLoading(false);
        clear && clear();
      });
  }

  return (
    <>
      <ActionButton
        id="assign"
        Icon={AssignLogo}
        onClick={toggle}
        tooltip={`tooltip.device.${deviceMode}.assign`}
        subtle
      >
        {t("pages.device.assign")}
      </ActionButton>
      {show && (
        <ModalCard wrapper={wrapper}>
          <ModalCardTitle>
            {t("pages.device.modal.assign.title", { count: selected.length })}
          </ModalCardTitle>
          <ModalCardBody>
            <h2 className=" text-justify ">
              {t("pages.device.modal.assign.body", { count: selected.length })}
            </h2>
            <div className="text-left">
              {t("pages.device.table.category")}

              <CategoryCombo
                className="text-red-700"
                setSelected={setSelCategory}
                includeUnassigned="True"
              />
            </div>
            <div className="text-left">
              {t("pages.device.table.room")}
              <RoomCombo setSelected={setSelRoom} includeUnassigned="True" />
            </div>
          </ModalCardBody>
          <ModalCardButtons>
            <StandardButton onClick={toggle} subtle>
              {t("pages.device.modal.cancel")}
            </StandardButton>
            <StandardButton loading={loading} onClick={handleAssignment}>
              {t("pages.device.assign")}
            </StandardButton>
          </ModalCardButtons>
        </ModalCard>
      )}
    </>
  );
}

export default DeviceAssignModal;
