import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import DeviceTable from "../components/device/DeviceTable";
import DeviceActionModal from "../components/device/DeviceActionModal";
import DeviceAssignModal from "../components/device/DeviceAssignModal";
import LoadingTable from "../components/layout/loading/LoadingTable";
import RefreshButton from "../components/layout/buttons/RefreshButton";
import DeviceModeBar from "../components/layout/DeviceModeBar";
import { PortalContext } from "../PortalContext";
import { ReactComponent as AddLogo } from "../components/icons/add.svg";
import MoreButtons, {
  MoreButtonsChild,
} from "../components/layout/buttons/MoreButtons";
import IpadOnboarding from "../components/device/IpadOnboarding";
import DeviceAssignUnenrolledModal from "../components/device/DeviceAssignUnenrolledModal";
import { useCheckLicenseStatus } from "../tools/utilties";
import { useNavigate } from "react-router-dom";
import MacosOnboarding from "../components/device/MacosOnboarding";
import { useDevice } from "../hooks/useDevice";
import PageTitle from "../components/layout/PageTitle";
import PageContent, { PageContentBody } from "../components/layout/PageContent";
import Sidebar from "../components/layout/Sidebar";
function Device({ refresh }) {
  const { deviceMode, ipadOnboarded, macosOnboarded } =
    useContext(PortalContext);
  const navigate = useNavigate();
  const { t } = useTranslation("global");
  const [filter, setFilter] = useState("99999999-9999-9999-9999-999999999999");
  const [selected, setSelected] = useState([]);
  const [mixed, setMixed] = useState(false);
  const [unenrolled, setUnenrolled] = useState(false);
  const [columnFilterBar, setColumnFilterBar] = useState(false);
  const userRole = sessionStorage.getItem("role");
  const [columnList, setColumnList] = useState([]); //[{id:"deviceCategoryDisplayName",visible:true},{id:"roomDisplayName",visible:true},{id:"lastSyncDateTime",visible:true},{id:"model",visible:true},{id:"operatingSystem",visible:true},{id:"serialNumber",visible:true},{id:"manufacturer",visible:true}])
  const {
    windowsData,
    ipadData,
    macosData,
    unenrolledData,
    isLoading,
    update,
  } = useDevice();
  const handleColumnListInitialize = (columns) => {
    let initializedColumnList = [];
    columns.map((column) => {
      if (column.columnDef.canHide) {
        initializedColumnList.push({
          id: column.id,
          label: column.columnDef.label,
          visible: column.getIsVisible(),
        });
      }
    });
    setColumnList(initializedColumnList);
  };
  const handleColumnListChange = (e) => {
    let newColumnList = [];
    columnList.map((column) => {
      if (column.id === e.id) {
        column.visible = e.checked;
      }
      newColumnList.push(column);
    });
    setColumnList(newColumnList);
  };
  useCheckLicenseStatus();

  const clear = () => {
    // tableRef.current.clearSelected();
  };
  useEffect(() => {
    let enrolled = true;
    let mix = false;
    selected.map((device) => {
      enrolled = enrolled && !device.original.targetType.includes("unenrolled");
      if (mix) {
        if (mix !== device.original.targetType) {
          setMixed(true);
        }
      } else {
        mix = device.original.targetType;
        setMixed(false);
      }
    });
  }, [selected]);
  const enrolledButtons = (
    <>
      <DeviceActionModal action="sync" selected={selected} clear={clear} />
      {(deviceMode === "windows" || deviceMode === "macos") && (
        <DeviceActionModal action="reset" selected={selected} clear={clear} />
      )}

      {deviceMode === "ipad" && (
        <DeviceActionModal
          action="passcode"
          selected={selected}
          clear={clear}
        />
      )}
      <DeviceAssignModal selected={selected} clear={clear} />
      <MoreButtons free>
        {deviceMode === "ipad" ? (
          <DeviceActionModal
            action="reset"
            selected={selected}
            clear={clear}
            more
          />
        ) : null}
        <DeviceActionModal
          action="reboot"
          selected={selected}
          clear={clear}
          more
        />
        {userRole === "smp.administrator" && (
          <DeviceActionModal
            action="remove"
            selected={selected}
            clear={clear}
            more
          />
        )}
        {deviceMode === "windows" && (
          <MoreButtonsChild
            id="adddevice"
            Icon={AddLogo}
            onClick={() => navigate("/adddevice")}
            tooltip={`tooltip.device.${deviceMode}.add`}
            position={"bottom"}
          >
            {t("pages.device.add")}
          </MoreButtonsChild>
        )}
      </MoreButtons>
    </>
  );

  const unenrolledButtons = (
    <>
      <DeviceActionModal action="remove" selected={selected} clear={clear} unenrolled />
      <DeviceAssignUnenrolledModal selected={selected} clear={clear} />
    </>
  );
  return (
    <>
      {/*Title Bar*/}
      <PageTitle title={t("pages.device.title")}>
        <RefreshButton loading={isLoading} handleRefresh={update} />
        {!mixed && (!unenrolled ? enrolledButtons : unenrolledButtons)}
      </PageTitle>

      {/* Content */}
      <PageContent sidebar full>
        <Sidebar
          unassigned="True"
          filter={filter}
          setFilter={setFilter}
          globalId="99999999-9999-9999-9999-999999999999"
          compare="id"
        />
        <PageContentBody>
          <DeviceModeBar
            unenrolled={setUnenrolled}
            setFilter={setColumnFilterBar}
            columns={columnList}
            setColumns={handleColumnListChange}
            target="device"
          />
          {isLoading ? (
            <LoadingTable />
          ) : unenrolled ? (
            unenrolledData ? (
              <DeviceTable
                deviceData={unenrolledData}
                setSelected={setSelected}
                filter={filter}
                showColumns={columnList}
                setShowColumns={handleColumnListInitialize}
                showFilter={columnFilterBar}
                dataType="unenrolled"
              />
            ) : (
              <LoadingTable />
            )
          ) : deviceMode === "windows" ? (
            windowsData ? (
              <DeviceTable
                deviceData={windowsData}
                setSelected={setSelected}
                filter={filter}
                showColumns={columnList}
                setShowColumns={handleColumnListInitialize}
                showFilter={columnFilterBar}
                dataType="windows"
              />
            ) : (
              <LoadingTable />
            )
          ) : deviceMode === "ipad" ? (
            ipadOnboarded === false || ipadOnboarded === "requested" ? (
              <IpadOnboarding target="device" />
            ) : ipadData && ipadOnboarded === true ? (
              <DeviceTable
                deviceData={ipadData}
                setSelected={setSelected}
                reload={refresh}
                filter={filter}
                showColumns={columnList}
                setShowColumns={handleColumnListInitialize}
                showFilter={columnFilterBar}
                dataType="ipads"
              />
            ) : (
              <LoadingTable />
            )
          ) : deviceMode === "macos" ? (
            macosOnboarded === false || macosOnboarded === "requested" ? (
              <MacosOnboarding target="device" />
            ) : macosData && ipadOnboarded === true ? (
              <DeviceTable
                deviceData={macosData}
                setSelected={setSelected}
                filter={filter}
                showColumns={columnList}
                setShowColumns={handleColumnListInitialize}
                showFilter={columnFilterBar}
                dataType="macos"
              />
            ) : (
              <LoadingTable />
            )
          ) : (
            <LoadingTable />
          )}
        </PageContentBody>
      </PageContent>
    </>
  );
}

export default Device;
