import { useCallback, useContext, useState } from "react";
import { PortalContext } from "../PortalContext";
import { getApi } from "../tools/axiosInstances";
import { useEffect } from "react";

export const useApplication = (filter) => {
  const [applications, setApplications] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { refresh } = useContext(PortalContext);
  useEffect(() => {
    setIsLoading(true);
    getApi(`/application/status/${filter}`, {}).then((response) => {
      setApplications(response.data);
      setIsLoading(false);
    });
  }, [refresh,filter]);
  return { applications, isLoading };
};
