import React from "react";

function PageContent({ sidebar, children, full }) {
  return (
    <>
      <div
        className={`mx-auto ${
          full ? "w-full" : "max-w-6xl"
        } py-5 flex h-[calc(100vh-12rem)] overflow-hidden justify-between `}
      >
        {full && <div className="min-w-[calc((100%-72rem)/2)]"></div>}
        {sidebar ? (
          <div className="flex w-full">{children}</div>
        ) : (
          <div className="w-full">{children}</div>
        )}
      </div>
    </>
  );
}

export function PageContentBody({ children }) {
  return (
    <div className="w-full overflow-y-auto h-[calc(100vh-300px)] mb-12">
      {children}
    </div>
  );
}

export default PageContent;
